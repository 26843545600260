import React, {useEffect} from 'react';
import ProductSchema from '../components/ProductSchema';
import ProductHero from '../components/ProductHero';
import Seo from '../components/Seo';
import getApp from 'magic-tricks/lib/getApp';
import ProductComponentList from '../components/ProductComponentList';
import ProductStickyNav from '../components/ProductStickyNav';

export default ({pageContext, location}) => {
	const app = getApp();
	const {product = {}, template = {}, header = {}, cart = {}} = pageContext;
	const { customerDiscountTags = [] } = cart;

	const {
		productId,
		color,
		sku,
		title,
		slug,
		drugName,
		activeIngredients,
		description,
		heroImages,
		subscription,
		cents,
		variantId,
		variants = [],
		components = [],
		seo = {},
		hideFromSearchEngine,
		hideLowInventoryProduct,
		link,
		link2,
		linkTitle1,
		linkTitle2,
		amazon_link,
		wallmart_link,
		findstore_link,
		buyonamazon_link,
		upc,
		useLayout2=false,
		enableBuyWithPrime=false,
		enableSquareProductImages=false,
		promo_offer
	} = product;

	const {
		medicineCabinetTitle,
		medicineCabinetDescription,
		medicineCabinetCta,
		guaranteeDetails,
	} = template;

	const metaTitle = seo.metaTitle || `${product.title} - Genexa`;
	const openGraphTitle = seo.openGraphTitle || `${product.title} - Genexa`;
	const openGraphImage = seo.openGraphImage || product.mainImage;
	const twitterTitle = seo.twitterTitle || `${product.title} - Genexa`;
	const twitterImage = seo.twitterImage || product.mainImage;
    const islayout2=useLayout2?true:false;
    const isBuyWithPrimeEnabled=enableBuyWithPrime?true:false;
	const isSquareProductImagesEnabled=enableSquareProductImages?true:false;

	// Track product view only once
	useEffect(() => {
		if (app.analytics) {
			app.analytics.viewProduct({
				id: product.sku,
				name: product.title,
				brand: 'Genexa',
				category: 'Medication',
				variant: product.variantTitle,
				quantity: 1,
				price: product.cents * 100,
			});
		}

	}, [0]);

	return (
		<React.Fragment>
			<Seo
				metaTitle={metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={twitterImage}
				pathname={location.pathname}
				noIndexNoFollow={hideFromSearchEngine}
			/>
			<ProductSchema
				name={product.title}
				image={product.mainImage ? product.mainImage.url : null}
				description={description || seo.metaDescription}
				sku={product.variants[0].sku}
				price={product.variants[0].price}
				url={`https://www.genexa.com${location.pathname}`}
			/>
			<ProductHero
				isSquareProductImagesEnabled={isSquareProductImagesEnabled}
				isBuyWithPrimeEnabled={isBuyWithPrimeEnabled}
				islayout2={islayout2}
				promo_offer={promo_offer}
				productId={productId}
				sku={sku}
				title={title}
				color={color}
				slug={slug}
				cents={cents}
				variantId={variantId}
				subscription={subscription}
				drugName={drugName}
				activeIngredients={activeIngredients}
				description={description}
				heroImages={heroImages}
				medicineCabinetTitle={medicineCabinetTitle}
				medicineCabinetDescription={medicineCabinetDescription}
				medicineCabinetCta={medicineCabinetCta}
				guaranteeDetails={guaranteeDetails}
				variants={variants}
				app={app}
				hideLowInventoryProduct={hideLowInventoryProduct}
				link={link}
				link2={link2}
				linkTitle1={linkTitle1}
				linkTitle2={linkTitle2}
				tags={product.tags}
				amazon_link={amazon_link}
				wallmart_link={wallmart_link}
				findstore_link={findstore_link}
				buyonamazon_link={buyonamazon_link}
				upc={upc}
			/>
			<ProductComponentList product={product} components={components} />
		</React.Fragment>
	);
};
